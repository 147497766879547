.calendar-typography {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
}
.side-menu-item {
  font-size: 16px;
  border-radius: var(--menu-border-radius);
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:after {
  content: none !important;
}
.side-menu-item:active {
  background: transparent;
}
.side-menu-item-icon {
  line-height: 1;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item .side-menu-item-text {
  text-transform: capitalize;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:hover .side-menu-item-icon,
.side-menu-item:hover .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global(.ant-menu-item-selected) {
  background: var(--side-menu-active-menu-item-background);
}
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-icon,
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global.ant-menu-item {
  height: 45px;
  padding: 6px 16px;
}
.side-menu-item:global.ant-menu-item:not(:last-child) {
  margin: 0 0 16px;
}
.side-menu-item:global.ant-menu-item:last-child {
  margin: 0;
}
.disabled-button[disabled],
.disabled-button[disabled]:hover,
.disabled-button[disabled]:focus,
.disabled-button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.select-option {
  align-items: center;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--text-color);
}
.select-option:hover {
  background-color: var(--dropdown-menu-hover);
}
.select-option:global.ant-select-item-option-selected,
.select-option:global.ant-select-item-option-active {
  background-color: var(--dropdown-menu-hover);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.pagination .ant-pagination-next svg {
  transform: rotate(180deg);
}
.pagination .ant-pagination-prev,
.pagination .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.pagination .ant-pagination-prev svg:hover,
.pagination .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-prev svg path,
.pagination .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.pagination .ant-pagination-prev.ant-pagination-disabled svg,
.pagination .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.pagination .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.pagination .ant-pagination-item:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.pagination .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.pagination .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.pagination .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.pagination .ant-pagination-item-active:hover {
  opacity: 1;
}
.pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.pagination .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.pagination .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus {
  font-size: 14px !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color) !important;
  background: transparent !important;
  caret-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.default-table-padding .ant-table table .ant-table-cell {
  padding: 9px 16px 9px 6px;
}
.default-table-padding .ant-table table .ant-table-cell:first-child {
  padding: 9px 16px 10px 9px;
}
.table-nowrap .ant-table-tbody > tr > td,
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.container .form-item {
  display: block;
  margin: 0;
}
.container .form-item :global .ant-form-item-label {
  padding: 0;
  margin: 0 0 4px 16px;
  text-align: left;
}
.container .form-item :global .ant-form-item-label > label.ant-form-item-required,
.container .form-item :global .ant-form-item-label > label.ant-form-item-no-colon {
  height: 18px;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: var(--text-color);
}
.container .form-item :global .ant-form-item-label > label.ant-form-item-required:before,
.container .form-item :global .ant-form-item-label > label.ant-form-item-no-colon:before {
  display: none;
}
.container .form-item :global .ant-form-item-explain.ant-form-item-explain-error {
  padding-left: 16px;
  margin-top: 2px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  z-index: 1000;
  color: #E14449;
}
.container :global .ant-form-item-with-help {
  margin-bottom: 0;
}
.container :global .ant-form-item-has-error .ant-picker,
.container :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.container :global .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background-color: var(--input-background);
  border-color: #E14449;
}
.container :global .ant-form-item-has-error .ant-picker::placeholder,
.container :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input::placeholder,
.container :global .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector::placeholder,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper::placeholder,
.container :global .ant-form-item-has-error .ant-picker input::placeholder,
.container :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input input::placeholder,
.container :global .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector input::placeholder,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper input::placeholder {
  color: var(--input-placeholder-text-color);
}
.container :global .ant-form-item-has-error .ant-picker:hover,
.container :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.container :global .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector:hover,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.container :global .ant-form-item-has-error .ant-picker:active,
.container :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:active,
.container :global .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector:active,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:active,
.container :global .ant-form-item-has-error .ant-picker:focus-within,
.container :global .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus-within,
.container :global .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector:focus-within,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus-within {
  background-color: var(--input-background);
  border: 1px solid var(--input-focus);
  box-shadow: none;
}
