.calendar-typography {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
}
.side-menu-item {
  font-size: 16px;
  border-radius: var(--menu-border-radius);
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:after {
  content: none !important;
}
.side-menu-item:active {
  background: transparent;
}
.side-menu-item-icon {
  line-height: 1;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item .side-menu-item-text {
  text-transform: capitalize;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:hover .side-menu-item-icon,
.side-menu-item:hover .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global(.ant-menu-item-selected) {
  background: var(--side-menu-active-menu-item-background);
}
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-icon,
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global.ant-menu-item {
  height: 45px;
  padding: 6px 16px;
}
.side-menu-item:global.ant-menu-item:not(:last-child) {
  margin: 0 0 16px;
}
.side-menu-item:global.ant-menu-item:last-child {
  margin: 0;
}
.disabled-button[disabled],
.disabled-button[disabled]:hover,
.disabled-button[disabled]:focus,
.disabled-button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.select-option {
  align-items: center;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--text-color);
}
.select-option:hover {
  background-color: var(--dropdown-menu-hover);
}
.select-option:global.ant-select-item-option-selected,
.select-option:global.ant-select-item-option-active {
  background-color: var(--dropdown-menu-hover);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.pagination .ant-pagination-next svg {
  transform: rotate(180deg);
}
.pagination .ant-pagination-prev,
.pagination .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.pagination .ant-pagination-prev svg:hover,
.pagination .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-prev svg path,
.pagination .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.pagination .ant-pagination-prev.ant-pagination-disabled svg,
.pagination .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.pagination .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.pagination .ant-pagination-item:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.pagination .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.pagination .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.pagination .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.pagination .ant-pagination-item-active:hover {
  opacity: 1;
}
.pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.pagination .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.pagination .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus {
  font-size: 14px !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color) !important;
  background: transparent !important;
  caret-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.default-table-padding .ant-table table .ant-table-cell {
  padding: 9px 16px 9px 6px;
}
.default-table-padding .ant-table table .ant-table-cell:first-child {
  padding: 9px 16px 10px 9px;
}
.table-nowrap .ant-table-tbody > tr > td,
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.background-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 105px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: capitalize;
  border-radius: var(--bg-wrapper-border-radius);
}
.background-wrapper .icon {
  margin-right: 9px;
}
.background-wrapper .icon svg {
  fill: var(--button-icon-color);
}
.sumsub,
.profit {
  background: var(--balance-background);
  height: 41px;
  line-height: 150%;
  letter-spacing: 0.02em;
  border-radius: var(--button-border-radius);
}
.sumsub .text,
.profit .text {
  color: var(--balance-icon-color);
}
.sumsub .icon path,
.profit .icon path {
  fill: var(--balance-icon-color);
}
.lead1,
.lead2,
.lead3,
.lead4,
.active,
.funded,
.enabled,
.approved {
  height: 34px;
  width: 109px;
  background: rgba(81, 181, 109, 0.1);
}
.lead1 .icon,
.lead2 .icon,
.lead3 .icon,
.lead4 .icon,
.active .icon,
.funded .icon,
.enabled .icon,
.approved .icon,
.lead1 .text,
.lead2 .text,
.lead3 .text,
.lead4 .text,
.active .text,
.funded .text,
.enabled .text,
.approved .text {
  color: #51B56D;
}
.lead1-4,
.dormant,
.posted,
.rejected,
.disabled,
.declined {
  height: 34px;
  width: 109px;
  background: rgba(225, 68, 73, 0.1);
}
.lead1-4 .icon,
.dormant .icon,
.posted .icon,
.rejected .icon,
.disabled .icon,
.declined .icon,
.lead1-4 .text,
.dormant .text,
.posted .text,
.rejected .text,
.disabled .text,
.declined .text {
  color: #E14449;
}
.demo {
  height: 34px;
  width: 109px;
  background: rgba(132, 132, 132, 0.1);
}
.undefined {
  height: 34px;
  width: 109px;
  background: rgba(132, 132, 132, 0.1);
}
.new,
.pending {
  height: 34px;
  width: 109px;
  background: rgba(253, 106, 60, 0.1);
}
.new .icon,
.pending .icon,
.new .text,
.pending .text {
  color: #FD6A3C;
}
.buy {
  display: flex;
  min-width: 67px;
  width: 67px;
  height: 34px;
  background: #51B56D;
}
.buy .text {
  color: #FFF;
}
.sell {
  display: flex;
  min-width: 67px;
  width: 67px;
  height: 34px;
  background: #E14449;
}
.sell .text {
  color: #FFF;
}
.button {
  background: var(--input-background);
  height: 35px;
  max-width: 130px;
  min-width: 121px;
  border: 1px solid #fff;
  border-radius: var(--button-border-radius);
}
