.calendar-typography {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
}
.side-menu-item {
  font-size: 16px;
  border-radius: var(--menu-border-radius);
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:after {
  content: none !important;
}
.side-menu-item:active {
  background: transparent;
}
.side-menu-item-icon {
  line-height: 1;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item .side-menu-item-text {
  text-transform: capitalize;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:hover .side-menu-item-icon,
.side-menu-item:hover .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global(.ant-menu-item-selected) {
  background: var(--side-menu-active-menu-item-background);
}
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-icon,
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global.ant-menu-item {
  height: 45px;
  padding: 6px 16px;
}
.side-menu-item:global.ant-menu-item:not(:last-child) {
  margin: 0 0 16px;
}
.side-menu-item:global.ant-menu-item:last-child {
  margin: 0;
}
.disabled-button[disabled],
.disabled-button[disabled]:hover,
.disabled-button[disabled]:focus,
.disabled-button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.select-option {
  align-items: center;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--text-color);
}
.select-option:hover {
  background-color: var(--dropdown-menu-hover);
}
.select-option:global.ant-select-item-option-selected,
.select-option:global.ant-select-item-option-active {
  background-color: var(--dropdown-menu-hover);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.pagination .ant-pagination-next svg {
  transform: rotate(180deg);
}
.pagination .ant-pagination-prev,
.pagination .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.pagination .ant-pagination-prev svg:hover,
.pagination .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-prev svg path,
.pagination .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.pagination .ant-pagination-prev.ant-pagination-disabled svg,
.pagination .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.pagination .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.pagination .ant-pagination-item:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.pagination .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.pagination .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.pagination .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.pagination .ant-pagination-item-active:hover {
  opacity: 1;
}
.pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.pagination .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.pagination .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus {
  font-size: 14px !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color) !important;
  background: transparent !important;
  caret-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.default-table-padding .ant-table table .ant-table-cell {
  padding: 9px 16px 9px 6px;
}
.default-table-padding .ant-table table .ant-table-cell:first-child {
  padding: 9px 16px 10px 9px;
}
.table-nowrap .ant-table-tbody > tr > td,
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.container {
  display: grid;
  grid-template-columns: 1fr 128px;
  position: relative;
  grid-row-gap: 20px;
  grid-column-gap: 32px;
  min-width: 352px;
}
.container-header {
  grid-column: 1/3;
}
.container-title {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--text-color);
}
.container .skeleton :global .ant-skeleton-paragraph {
  margin-top: 1em;
}
.container .spinner {
  height: 100%;
}
.container .labels {
  height: 140px;
  max-height: 210px;
  padding: 0 8px 0 0;
  list-style: none;
  overflow-y: auto;
  scrollbar-width: thin;
}
.container .labels::-webkit-scrollbar {
  width: 5px;
}
.container .labels::-webkit-scrollbar-track {
  margin: 16px;
  border-radius: 10px;
  background: var(--button-dissable);
}
.container .labels::-webkit-scrollbar-thumb {
  background: var(--scrollbar-handle);
  border-radius: 10px;
}
.container .labels .label {
  display: flex;
  margin-bottom: 8px;
}
.container .labels .label:last-child {
  margin-bottom: 0;
}
.container .labels .label-dot {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
}
.container .labels .label-name {
  font-size: 14px;
  line-height: 16px;
  color: var(--text-color);
}
.container .labels .label-value {
  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--text-secondary);
}
.container .tablet {
  width: 100%;
}
@media (max-width: 575.98px) {
  .container {
    min-width: initial;
  }
}
.container .chart :global .apexcharts-canvas svg {
  overflow: visible;
}
.container .chart :global .apexcharts-tooltip {
  border-radius: 10px;
  border: none !important;
  background: var(--tooltip) !important;
  backdrop-filter: blur(20px);
  box-shadow: none !important;
}
.container .chart :global .apexcharts-tooltip .tooltip {
  padding: 4px 8px;
}
.container .chart :global .apexcharts-tooltip .tooltip-title {
  margin-bottom: 4px;
  font-family: 'Cairo', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--password-show);
}
.container .chart :global .apexcharts-tooltip .tooltip-value {
  font-family: 'Cairo', -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--text-color);
}
.tile {
  height: 220px;
  padding: 24px;
  border-radius: 12px;
  background: var(--selector-background);
}
.block {
  grid-template-columns: minmax(150px, 1fr) auto;
  grid-template-rows: 27px 1fr;
  grid-column-gap: 55px;
  align-items: center;
  max-width: 500px;
  min-width: 400px;
  width: 100%;
}
.block .labels {
  grid-column: 1/2;
  grid-row: 2/3;
  margin: 15px 0 0;
}
.block .labels .label {
  margin-bottom: 20px;
}
.block .labels .label-dot {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  border-radius: 50%;
}
@media (max-width: 1199.98px) {
  .block {
    max-width: initial;
  }
}
@media (max-width: 575.98px) {
  .block {
    grid-template-columns: 100%;
    grid-template-rows: 27px 1fr 1fr;
    min-width: initial;
  }
  .block .container-header {
    grid-column: 1;
  }
  .block .chart {
    grid-row: 2/3;
  }
  .block .chart :global(.apexcharts-canvas) {
    margin: 0 auto;
  }
  .block .labels {
    grid-column: 1;
    grid-row: 3/4;
    margin: 15px 0 0;
  }
  .block .labels .label {
    margin-bottom: 20px;
  }
  .block .labels .label-dot {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    border-radius: 50%;
  }
}
