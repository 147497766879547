.calendar-typography {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
}
.side-menu-item {
  font-size: 16px;
  border-radius: var(--menu-border-radius);
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:after {
  content: none !important;
}
.side-menu-item:active {
  background: transparent;
}
.side-menu-item-icon {
  line-height: 1;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item .side-menu-item-text {
  text-transform: capitalize;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:hover .side-menu-item-icon,
.side-menu-item:hover .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global(.ant-menu-item-selected) {
  background: var(--side-menu-active-menu-item-background);
}
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-icon,
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global.ant-menu-item {
  height: 45px;
  padding: 6px 16px;
}
.side-menu-item:global.ant-menu-item:not(:last-child) {
  margin: 0 0 16px;
}
.side-menu-item:global.ant-menu-item:last-child {
  margin: 0;
}
.disabled-button[disabled],
.disabled-button[disabled]:hover,
.disabled-button[disabled]:focus,
.disabled-button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.select-option {
  align-items: center;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--text-color);
}
.select-option:hover {
  background-color: var(--dropdown-menu-hover);
}
.select-option:global.ant-select-item-option-selected,
.select-option:global.ant-select-item-option-active {
  background-color: var(--dropdown-menu-hover);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.pagination .ant-pagination-next svg {
  transform: rotate(180deg);
}
.pagination .ant-pagination-prev,
.pagination .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.pagination .ant-pagination-prev svg:hover,
.pagination .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-prev svg path,
.pagination .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.pagination .ant-pagination-prev.ant-pagination-disabled svg,
.pagination .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.pagination .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.pagination .ant-pagination-item:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.pagination .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.pagination .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.pagination .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.pagination .ant-pagination-item-active:hover {
  opacity: 1;
}
.pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.pagination .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.pagination .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus {
  font-size: 14px !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color) !important;
  background: transparent !important;
  caret-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.default-table-padding .ant-table table .ant-table-cell {
  padding: 9px 16px 9px 6px;
}
.default-table-padding .ant-table table .ant-table-cell:first-child {
  padding: 9px 16px 10px 9px;
}
.table-nowrap .ant-table-tbody > tr > td,
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
:root,
[data-theme='dark'] {
  --tooltip: linear-gradient(162.22deg, rgba(255, 255, 255, 0.4) -287.8%, rgba(255, 255, 255, 0) 126.11%);
  --layout-color: #141417;
  --text-color: #FFF;
  --widget-background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.2) -267.85%, rgba(255, 255, 255, 0) 138.29%);
  --interactive-widget: #202023;
  --dropdown-menu-hover: #2b2b2e;
  --modal-header-color: rgba(32, 32, 35, 0.87);
  --modal-text: #FFF;
  --modal-body: #141417;
  --modal-header-border: #111113;
  --tweak-color: #ffffff;
  --tweak-disable-label-color: rgba(68, 68, 68, 0.7);
  --tweak-disable-text-color: rgba(255, 255, 255, 0.2);
  --badge-shadow: #161717;
  --tooltip-background: #161717;
  --placeholder: rgba(255, 255, 255, 0.3);
  --scrollbar-handle: rgba(255, 255, 255, 0.2);
  --charts-list-color: linear-gradient(133.63deg, rgba(255, 255, 255, 0.24) -240.03%, rgba(255, 255, 255, 0) 106.28%);
  --no-data-text: rgba(255, 255, 255, 0.5);
  --text-secondary: rgba(255, 255, 255, 0.5);
  --grid-color: rgba(255, 255, 255, 0.05);
  --input-background: rgba(255, 255, 255, 0.05);
  --input-focus: rgba(255, 255, 255, 0.3);
  --widget-container: linear-gradient(131.94deg, rgba(255, 255, 255, 0.08) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --selector-background: var(--widget-background);
  --transparent-gradient: linear-gradient(178.99deg, rgba(255, 255, 255, 0.04) -14.63%, rgba(255, 255, 255, 0) 99.24%);
  --avatar-background: rgba(255, 255, 255, 0.2);
  --avatar-icon: #FFF;
  --drag-active: #202023;
  --info-title: rgba(255, 255, 255, 0.6);
  --person-email: rgba(255, 255, 255, 0.5);
  --search-transparent: transparent;
  --tweak-filter: rgba(255, 255, 255, 0.05);
  --button-dissable: rgba(255, 255, 255, 0.1);
  --button-hovered: rgba(255, 255, 255, 0.15);
  --default-setting-button: var(--selector-background);
  --term-border-active: #000;
  --term-color: #18181b;
  --password-show: rgba(255, 255, 255, 0.7);
  --password-hidden: rgba(255, 255, 255, 0.3);
  --auth-background: linear-gradient(131.94deg, #1b1b1d -22.88%, #161619 91.76%);
  --auth-layout: #141417;
  --menu-item-color: rgba(255, 255, 255, 0.5);
  --menu-item-active-color: #FFF;
  --menu-link-active-background: rgba(255, 255, 255, 0.05);
  --sidebar-background: #1e1e21;
  --sidebar-rect: #FFF;
  --sidebar-burger: #FFF;
  --chart-marker-shadow: 0 0 0 5px #000000;
  --sidebar-circle-border: #212121;
  --main-search-text: #8f8f8f;
  --search-background: linear-gradient(125.57deg, rgba(255, 255, 255, 0.2) -211.9%, rgba(255, 255, 255, 0) 128.55%);
  --header-notification-bg: #202023;
  --header-notification-static: #898989;
  --header-notification-shadow: rgba(0, 0, 0, 0.04);
  --header-notification-hover: #b8b8b8;
  --header-notification-pushed: #5a5a5a;
  --header-notification-circ: #e14449;
  --header-notification-items: #2ccdcd;
  --header-notification-hr: #4d4d4d;
  --header-notification-clock: #985eff;
  --header-notification-warn: #eb5757;
  --header-notification-info: #00a15d;
  --header-notification-filter: #202023;
  --header-profit-bg: #162629;
  --header-profit: #2ccdcd;
  --header-prole-bg: linear-gradient(90deg, #742CCE, #1811A2);
  --header-prole-arrow: #FFF;
  --header-logout-button-static: #898989;
  --header-logout-button-hover: #fff;
  --header-logout-popup-bg: #141417;
  --header-logout-popup-cancel-button-bg: rgba(255, 255, 255, 0.05);
  --tab-color: #ffffff;
  --table-row-hover: #2a2a2c;
  --table-row-pushed: #2e2e31;
  --table-row-selected: #2e2e31;
  --table-row-gradient-hover: linear-gradient(131.94deg, rgba(255, 255, 255, 0.1) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --table-thed-border: #4a4a4a;
  --table-thed-gradien-border: rgba(255, 255, 255, 0.1);
  --table-filter: #8c8c8c;
  --table-filter-hover: #ffffff;
  --table-pagination-arrow: #ffffff;
  --table-pagination-bg: #202023;
  --table-pagination-active: #fff;
  --table-showing-items: #29b6b6;
  --table-scroll-bar: #454547;
  --table-drawer-bottom-bg: #202020;
  --table-summary-bg: #212124;
  --table-see-all-btn: #252528;
  --table-see-all-btn-hover: #2c2c2e;
  --table-modal-hover: #2c2c2e;
  --table-modal: #212123;
  --client-search-bg: rgba(20, 20, 23);
  --client-search-border: rgba(32, 32, 35);
  --drawer-header-bg: #202020;
  --drawer-header-cross: #FFF;
  --drawer-body-bg: #141417;
  --drawer-input-number-border: #3f3f3f;
  --drawer-table-reset-border: transparent;
  --drawer-table-reset-border-hover: transparent;
  --select-dropdown-background: #202023;
  --select-dropdown--arrow: #FFF;
  --select-dropdown-hover: #2b2b2e;
  --spinner-color: #8e8e8e;
  --qr-code-color: #fff;
  --notification-modal-color: #fff;
  --notification-modal-backgound: rgba(0, 0, 0, 0.95);
}
[data-theme='light'] {
  --tooltip: rgba(102, 102, 102, 0.1);
  --text-color: #323232;
  --text-secondary: #8B8B8B;
  --layout-color: #F6F6F6;
  --widget-background: #F6F6F6;
  --widget-container: #FFF;
  --charts-list-color: #F6F6F6;
  --interactive-widget: #F6F6F6;
  --dropdown-widget: #F6F6F6;
  --grid-color: rgba(32, 32, 32, 0.05);
  --dropdown-menu-hover: #FFF;
  --selector-background: #FFF;
  --input-background: #F6F6F6;
  --input-focus: rgba(32, 32, 32, 0.2);
  --avatar-background: #FFF;
  --avatar-icon: rgba(32, 32, 32, 0.5);
  --transparent-gradient: #FFF;
  --drag-active: #F6F6F6;
  --placeholder: rgba(32, 32, 32, 0.5);
  --badge-shadow: #f6f6f6;
  --tooltip-background: #f6f6f6;
  --info-title: rgba(32, 32, 32, 0.5);
  --person-email: rgba(32, 32, 32, 0.5);
  --no-data-text: #202020;
  --search-transparent: #FFF;
  --tweak-filter: #FFF;
  --tweak-color: #323232;
  --tweak-disable-label-color: rgba(68, 68, 68, 0.2);
  --tweak-disable-text-color: rgba(0, 0, 0, 0.3);
  --button-dissable: rgba(255, 255, 255, 0.7);
  --button-hovered: rgba(255, 255, 255, 0.7);
  --default-setting-button: #f6f6f6;
  --term-color: #FFF;
  --term-border-active: #FFF;
  --password-show: #202020;
  --password-hidden: #8B8B8B;
  --auth-layout: url('./assets/auth-backgound.png') center no-repeat;
  --auth-border: transparent;
  --auth-background: #FFF;
  --scrollbar-handle: rgba(32, 32, 32, 0.2);
  --menu-item-color: #8f8f8f;
  --menu-item-active-color: #2ccdcd;
  --menu-link-active-background: rgba(51, 51, 51, 0.05);
  --sidebar-background: #FFF;
  --sidebar-rect: #333333;
  --sidebar-burger: #202020;
  --sidebar-person: #f6f6f6;
  --sidebar-circle-border: #f6f6f6;
  --search-background: #FFF;
  --header-profit-bg: #e2f2f2;
  --header-notification-bg: #ffffff;
  --header-notification-shadow: rgba(0, 0, 0, 0.04);
  --header-notification-circ: #e14449;
  --header-notification-filter: #FFF;
  --header-notification-hr: #d3d3d8;
  --header-prole-arrow: #2CCDCD;
  --header-logout-button-hover: #000;
  --header-logout-popup-bg: #fff;
  --header-logout-popup-cancel-button-bg: #f6f6f6;
  --table-row-hover: #f6f6f6;
  --table-row-pushed: #e6f7ff;
  --table-row-selected: #e6f7ff;
  --table-row-gradient-hover: linear-gradient(131.94deg, rgba(255, 255, 255, 0.1) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --table-thed-border: #d3d3d3;
  --table-thed-gradien-border: rgba(255, 255, 255, 0.1);
  --table-filter: #8f8f8f;
  --table-filter-hover: #202020;
  --table-pagination-arrow: #3a3a3a;
  --table-pagination-bg: #f6f6f6;
  --table-pagination-active: #fff;
  --table-showing-items: #7be0f4;
  --table-scroll-bar: #d2d2d2;
  --table-drawer-bottom-bg: #f5f5f5;
  --table-summary-bg: #f6f6f6;
  --table-see-all-btn: #f5f5f5;
  --table-see-all-btn-hover: #e9e9eb;
  --table-modal-hover: #f5f5f5;
  --table-modal: #FFF;
  --table-dropdown-last: #c7c7c7;
  --table-drawer-icon: #8f8f8f;
  --tab-color: #ffffff;
  --client-search-bg: rgba(255, 255, 255);
  --client-search-border: transperent;
  --drawer-header-bg: #FFF;
  --drawer-header-cross: #8f8f8f;
  --drawer-body-bg: #FFF;
  --drawer-input-number-border: rgba(32, 32, 32, 0.1);
  --drawer-table-reset-border: #f5f5f5;
  --drawer-table-reset-border-hover: #e9e9e9;
  --select-dropdown-background: #f6f6f6;
  --select-dropdown--arrow: #a2a2a2;
  --select-dropdown-hover: #8f8f8f;
  --modal-header-color: rgba(246, 246, 246, 0.94);
  --modal-text: #202020;
  --modal-subtitle: rgba(32, 32, 32, 0.5);
  --modal-body: #FFF;
  --modal-header-border: #d9d9d9;
  --spinner-color: #2ccdcd;
  --qr-code-color: #000;
  --notification-modal-color: #000;
  --notification-modal-backgound: rgba(255, 255, 255, 0.95);
}
[data-brand="FM"][data-theme="light"] {
  --text-color: #323232;
  --primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --button-text-color: #FFF;
  --button-icon-color: #1C1B1F;
  --button-border-radius: 10px;
  --ant-btn-border-radius: 4px;
  --ant-btn-primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-btn-primary-before-background: linear-gradient(90deg, #1811A2, #742CCE);
  --ant-btn-color: #FFF;
  --ant-btn-hover-color: #712BCD;
  --ant-btn-border-color: #712BCD;
  --button-outline-text-color: #1811A2;
  --button-outline-border-color: #1811A2;
  --button-primary-normal-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --button-primary-hover-background-color: linear-gradient(90deg, #1811A2, #742CCE);
  --button-primary-pressed-background-color: #1811A2;
  --button-primary-disabled-background-color: rgba(255, 255, 255, 0.7);
  --side-menu-background-color: #FFF;
  --side-menu-menu-item-text-color: #8f8f8f;
  --side-menu-active-menu-item-text-color: #2ccdcd;
  --side-menu-active-menu-item-background: rgba(51, 51, 51, 0.05);
  --account-manager-contact-background: #F6F6F6;
  --account-manager-title-color: black;
  --modal-header-color: rgba(246, 246, 246, 0.94);
  --modal-text: #202020;
  --modal-subtitle: rgba(32, 32, 32, 0.5);
  --modal-body: #FFF;
  --modal-header-border: #d9d9d9;
  --modal-input-background: #F6F6F6;
  --tab-active-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --tab-active-text-color: #FFF;
  --tab-inactive-text-color: #323232;
  --tab-hover-text-color: #323232;
  --table-border-radius: 12px;
  --table-row-selected-background-color: #F6F6F6;
  --cell-border-radius: 10px;
  --cell-hover-border-radius: 12px;
  --select-border-radius: 10px;
  --bg-wrapper-border-radius: 6px;
  --spin-border-radius: 12px;
  --pagination-item-active-background: linear-gradient(90deg, #742CCE, #1811A2);
  --pagination-border-radius: 10px;
  --pagination-item-active-border-radius: 12px;
  --pagination-item-text-color: #323232;
  --pagination-item-active-text-color: #FFF;
  --pagination-showing-number-items: #7be0f4;
  --pagination-show-more-arrows-color: #2CCDCD;
  --checkbox-border-radius: 5px;
  --checkbox-border: #8B8B8B;
  --checkbox-border-secondary: #2CCDCD;
  --checkbox-select-background: #2CCDCD;
  --checkbox-label-color: #202020;
  --switch-background: #9A60E8;
  --switch-btn-disabled-color: #919191;
  --search-border-radius: 12px;
  --input-border-radius: 10px;
  --input-background: #F6F6F6;
  --input-placeholder-text-color: rgba(32, 32, 32, 0.5);
  --date-picker-border-radius: 10px;
  --datepicker-icon-background: linear-gradient(90deg, #742CCE, #1811A2);
  --datepicker-icon-color: #FFF;
  --datepicker-cell-selected: #2CCDCD;
  --datepicker-cell-selected-text-color: #FFF;
  --datepicker-range-line-backgorund: rgba(44, 205, 205, 0.2);
  --datepicker-range-line-text-color: #323232;
  --ant-slider-rail-background: rgba(44, 205, 205, 0.2);
  --slider-background: #2CCDCD;
  --spinner-fill-color: #2CCDCD;
  --loader-secondary-color: rgba(51, 51, 51, 0.05);
  --loader-main-color: #2CCDCD;
  --card-border-radius: 12px;
  --menu-border-radius: 10px;
  --activity-border-radius: 12px;
  --activity-rise-color: #51B56D;
  --activity-rise-background-color: rgba(81, 181, 109, 0.1);
  --activity-down-color: #E14449;
  --activity-down-background-color: rgba(225, 68, 73, 0.1);
  --icon-perfomance-border-radius: 5px;
  --menu-icon-fill: #8f8f8f;
  --menu-selected-icon-fill: #2CCDCD;
  --ant-icon-fill-color: #FFF;
  --icon-search-color: #202020;
  --icon-check-background: linear-gradient(90deg, #742CCE, #1811A2);
  --icon-check-color: #FFF;
  --icon-check-border-radius: 12px;
  --top-partners-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --top-partners-text-main-color: #FFF;
  --top-partners-text-secondary-color: #8B8B8B;
  --top-partners-text-main-color-hover: rgba(255, 255, 255, 0.15);
  --top-partners-thed-border-bottom: rgba(255, 255, 255, 0.1);
  --top-partners-row-hover: linear-gradient(131.94deg, rgba(255, 255, 255, 0.1) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --client-status-border-radius: 6px;
  --balance-background: rgba(44, 205, 205, 0.1);
  --balance-text-color: #2CCDCD;
  --balance-icon-color: #2CCDCD;
  --my-commission-background-card: url("./assets/mask-group.png"), linear-gradient(180deg, #732DCE 0%, #1B12A3 100%);
  --my-commission-value-color: #FFF;
  --my-commission-title-color: rgba(255, 255, 255, 0.6);
  --my-commission-result-color: rgba(255, 255, 255, 0.6);
  --my-commission-changes-color: #FFF;
  --top-clients-main-color: #FFF;
  --top-clients-secondary-color: rgba(255, 255, 255, 0.5);
  --profile-settings-icons-color: #985EFF;
  --partners-profile-arrow-back: #2CCDCD;
  --tile-background-color: #F6F6F6;
  --tile-text-color: #8f8f8f;
  --tile-positive-change-color: #51B56D;
  --tile-negative-change-color: #E14449;
  --ant-select-country-text-color: #FFF;
  --ant-select-country-icon-color: #FFF;
  --ant-select-country-background-color: #2CCDCD;
  --ant-badge-color: #FFF;
  --ant-badge-backround-color: #2CCDCD;
  --ant-badge-border-radius: 10px;
  --ant-message-loading: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-message-success: #51B56D;
  --ant-message-text-loading: #FFF;
  --ant-message-anticon-loading: #1890ff;
  --link-color: #2CCDCD;
  --link-hover-color: #299F9F;
}
[data-brand="FM"][data-theme="dark"] {
  --text-color: #FFF;
  --primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --button-text-color: #FFF;
  --button-icon-color: #FFF;
  --button-border-radius: 10px;
  --ant-btn-border-radius: 4px;
  --ant-btn-primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-btn-primary-before-background: linear-gradient(90deg, #1811A2, #742CCE);
  --ant-btn-color: #FFF;
  --ant-btn-hover-color: #712BCD;
  --ant-btn-border-color: #712BCD;
  --button-outline-text-color: #1811A2;
  --button-outline-border-color: #1811A2;
  --button-primary-normal-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --button-primary-hover-background-color: linear-gradient(90deg, #1811A2, #742CCE);
  --button-primary-pressed-background-color: #1811A2;
  --button-primary-disabled-background-color: rgba(255, 255, 255, 0.7);
  --side-menu-background-color: #1e1e21;
  --side-menu-menu-item-text-color: rgba(255, 255, 255, 0.5);
  --side-menu-active-menu-item-text-color: #FFF;
  --side-menu-active-menu-item-background: rgba(255, 255, 255, 0.05);
  --account-manager-contact-background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.18) -267.85%, rgba(255, 255, 255, 0) 138.29%);
  --account-manager-title-color: #FFF;
  --modal-header-color: rgba(32, 32, 35, 0.87);
  --modal-text: rgba(255, 255, 255, 0.85);
  --modal-subtitle: rgba(255, 255, 255, 0.8);
  --modal-body: #141417;
  --modal-input-background: #202023;
  --tab-active-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --tab-active-text-color: #FFF;
  --tab-inactive-text-color: #FFF;
  --tab-hover-text-color: #FFF;
  --table-border-radius: 12px;
  --table-row-selected-background-color: #2e2e31;
  --cell-border-radius: 10px;
  --cell-hover-border-radius: 12px;
  --select-border-radius: 10px;
  --bg-wrapper-border-radius: 6px;
  --spin-border-radius: 12px;
  --pagination-item-active-background: linear-gradient(90deg, #742CCE, #1811A2);
  --pagination-border-radius: 10px;
  --pagination-item-active-border-radius: 12px;
  --pagination-item-text-color: #FFF;
  --pagination-item-active-text-color: #FFF;
  --pagination-showing-number-items: #29b6b6;
  --pagination-show-more-arrows-color: #2CCDCD;
  --checkbox-border-radius: 5px;
  --checkbox-border: #8B8B8B;
  --checkbox-border-secondary: #2CCDCD;
  --checkbox-select-background: #2CCDCD;
  --checkbox-label-color: #FFF;
  --switch-background: #9A60E8;
  --switch-btn-disabled-color: #919191;
  --search-border-radius: 12px;
  --input-border-radius: 10px;
  --input-background: rgba(255, 255, 255, 0.05);
  --input-placeholder-text-color: rgba(255, 255, 255, 0.3);
  --date-picker-border-radius: 10px;
  --datepicker-icon-background: linear-gradient(90deg, #742CCE, #1811A2);
  --datepicker-icon-color: #FFF;
  --datepicker-cell-selected: #2CCDCD;
  --datepicker-cell-selected-text-color: #FFF;
  --datepicker-range-line-backgorund: rgba(44, 205, 205, 0.2);
  --datepicker-range-line-text-color: #FFF;
  --ant-slider-rail-background: rgba(44, 205, 205, 0.2);
  --slider-background: #2CCDCD;
  --spinner-fill-color: #8B8B8B;
  --loader-secondary-color: rgba(255, 255, 255, 0.05);
  --loader-main-color: #8B8B8B;
  --card-border-radius: 12px;
  --menu-border-radius: 10px;
  --activity-border-radius: 12px;
  --activity-rise-color: #51B56D;
  --activity-rise-background-color: rgba(81, 181, 109, 0.1);
  --activity-down-color: #E14449;
  --activity-down-background-color: rgba(225, 68, 73, 0.1);
  --icon-perfomance-border-radius: 5px;
  --menu-icon-fill: #8f8f8f;
  --menu-selected-icon-fill: #FFF;
  --ant-icon-fill-color: #FFF;
  --icon-search-color: #FFF;
  --icon-check-background: linear-gradient(90deg, #742CCE, #1811A2);
  --icon-check-color: #FFF;
  --icon-check-border-radius: 12px;
  --top-partners-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --top-partners-text-main-color: #FFF;
  --top-partners-text-secondary-color: rgba(255, 255, 255, 0.85);
  --top-partners-text-main-color-hover: rgba(255, 255, 255, 0.85);
  --top-partners-thed-border-bottom: rgba(255, 255, 255, 0.1);
  --top-partners-row-hover: linear-gradient(131.94deg, rgba(255, 255, 255, 0.1) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --client-status-border-radius: 6px;
  --balance-background: rgba(44, 205, 205, 0.1);
  --balance-text-color: #2CCDCD;
  --balance-icon-color: #2CCDCD;
  --my-commission-background-card: url("./assets/mask-group.png"), linear-gradient(180deg, #732DCE 0%, #1B12A3 100%);
  --my-commission-value-color: #FFF;
  --my-commission-title-color: rgba(255, 255, 255, 0.6);
  --my-commission-result-color: rgba(255, 255, 255, 0.6);
  --my-commission-changes-color: #FFF;
  --top-clients-main-color: #FFF;
  --top-clients-secondary-color: rgba(255, 255, 255, 0.5);
  --profile-settings-icons-color: #985EFF;
  --partners-profile-arrow-back: #FFF;
  --tile-background-color: linear-gradient(125.86deg, rgba(255, 255, 255, 0.2) -267.85%, rgba(255, 255, 255, 0) 138.29%);
  --tile-text-color: rgba(255, 255, 255, 0.5);
  --tile-positive-change-color: #51B56D;
  --tile-negative-change-color: #E14449;
  --ant-select-country-text-color: #FFF;
  --ant-select-country-icon-color: #FFF;
  --ant-select-country-background-color: #2CCDCD;
  --ant-badge-color: #FFF;
  --ant-badge-backround-color: #2CCDCD;
  --ant-badge-border-radius: 10px;
  --ant-message-loading: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-message-success: #51B56D;
  --ant-message-text-loading: #FFF;
  --ant-message-anticon-loading: #1890ff;
  --link-color: rgba(255, 255, 255, 0.85);
  --link-hover-color: #FFF;
}
[data-brand="GP"][data-theme="light"] {
  --text-color: #110D3D;
  --no-data-text: #110D3D;
  --primary-background: #C0F826;
  --button-text-color: #110D3D;
  --button-icon-color: #1C1B1F;
  --ant-btn-border-radius: 0;
  --ant-btn-primary-background: #C0F826;
  --ant-btn-primary-before-background: #D5FA6E;
  --ant-btn-color: #110D3D;
  --ant-btn-hover-color: #110D3D;
  --ant-btn-border-color: #C0F826;
  --button-outline-border-color: #C0F826;
  --button-outline-text-color: #110D3D;
  --button-primary-normal-background-color: #C0F826;
  --button-primary-hover-background-color: #D5FA6E;
  --button-primary-pressed-background-color: #E0FC93;
  --button-primary-disabled-background-color: #ECEFF6;
  --side-menu-background-color: #FFF;
  --side-menu-menu-item-text-color: #53505B;
  --side-menu-active-menu-item-text-color: #110D3D;
  --side-menu-active-menu-item-background: #EFFAD0;
  --account-manager-contact-background: rgba(239, 250, 208, 0.4);
  --account-manager-title-color: #110D3D;
  --modal-header-color: rgba(249, 249, 249, 0.94);
  --modal-text: #202020;
  --modal-subtitle: rgba(32, 32, 32, 0.5);
  --modal-body: #FFF;
  --modal-header-border: #d9d9d9;
  --tab-active-background-color: #C0F826;
  --tab-active-text-color: #110D3D;
  --tab-inactive-text-color: #110D3D;
  --tab-hover-text-color: #110D3D;
  --table-border-radius: 0;
  --table-row-selected-background-color: #EFFAD0;
  --cell-border-radius: 0;
  --cell-hover-border-radius: 0;
  --button-border-radius: 0;
  --select-border-radius: 0;
  --bg-wrapper-border-radius: 0;
  --spin-border-radius: 0;
  --pagination-item-active-background: #C0F826;
  --pagination-border-radius: 0;
  --pagination-item-active-border-radius: 0;
  --pagination-item-text-color: #110D3D;
  --pagination-item-active-text-color: #110D3D;
  --pagination-showing-number-items: #9EA1B9;
  --pagination-show-more-arrows-color: #1C1B1F;
  --checkbox-border-radius: 0;
  --checkbox-border: #1C1B1F;
  --checkbox-border-secondary: #C0F826;
  --checkbox-select-background: #C0F826;
  --checkbox-label-color: #202020;
  --switch-background: #C0F826;
  --switch-btn-disabled-color: #EFFAD0;
  --search-border-radius: 0;
  --input-border-radius: 0;
  --input-background: #F6F6F6;
  --input-placeholder-text-color: rgba(32, 32, 32, 0.5);
  --date-picker-border-radius: 0;
  --datepicker-icon-background: #C0F826;
  --datepicker-icon-color: #1C1B1F;
  --datepicker-cell-selected: #C0F826;
  --datepicker-cell-selected-text-color: #110D3D;
  --datepicker-range-line-backgorund: #EAFDB7;
  --datepicker-range-line-text-color: #110D3D;
  --ant-slider-rail-background: #D5FA6E;
  --slider-background: #C0F826;
  --spinner-fill-color: #C0F826;
  --loader-secondary-color: rgba(55, 53, 61, 0.05);
  --loader-main-color: #C0F826;
  --card-border-radius: 0;
  --menu-border-radius: 0;
  --activity-border-radius: 0;
  --activity-rise-color: #80CA8A;
  --activity-rise-background-color: rgba(128, 202, 138, 0.1);
  --activity-down-color: #FE6B6B;
  --activity-down-background-color: rgba(254, 107, 107, 0.1);
  --icon-perfomance-border-radius: 5px;
  --menu-icon-fill: #1C1B1F;
  --menu-selected-icon-fill: #1C1B1F;
  --ant-icon-fill-color: #1C1B1F;
  --icon-search-color: #202020;
  --icon-check-background: #C0F826;
  --icon-check-color: #1C1B1F;
  --icon-check-border-radius: 0;
  --top-partners-background-color: #EFFAD0;
  --top-partners-text-main-color: #110D3D;
  --top-partners-text-secondary-color: #110D3D;
  --top-partners-text-main-color-hover: #C0F826;
  --top-partners-thed-border-bottom: #C0F826;
  --top-partners-row-hover: #D5FA6E;
  --client-status-border-radius: 0;
  --balance-background: #EFFAD0;
  --balance-text-color: #110D3D;
  --balance-icon-color: #110D3D;
  --my-commission-background-card: linear-gradient(71.54deg, #C0F826 13.69%, #EFFAD0 79.74%);
  --my-commission-value-color: #110D3D;
  --my-commission-title-color: rgba(17, 13, 61, 0.5);
  --my-commission-result-color: #110D3D;
  --my-commission-changes-color: #110D3D;
  --top-clients-main-color: #110D3D;
  --top-clients-secondary-color: #110D3D;
  --profile-settings-icons-color: #1C1B1F;
  --partners-profile-arrow-back: #1C1B1F;
  --tile-background-color: #F9F9F9;
  --tile-text-color: rgba(32, 32, 32, 0.5);
  --tile-positive-change-color: #80CA8A;
  --tile-negative-change-color: #FE6B6B;
  --ant-select-country-text-color: #110D3D;
  --ant-select-country-icon-color: #1C1B1F;
  --ant-select-country-background-color: #EFFAD0;
  --ant-badge-color: #110D3D;
  --ant-badge-backround-color: #EFFAD0;
  --ant-badge-border-radius: 0;
  --ant-message-loading: #C0F826;
  --ant-message-success: #80CA8A;
  --ant-message-text-loading: #110D3D;
  --ant-message-anticon-loading: #1C1B1F;
  --link-color: #2CCDCD;
  --link-hover-color: #299F9F;
}
[data-brand="GP"][data-theme="dark"] {
  --text-color: #FFF;
  --no-data-text: #FFF;
  --primary-background: #C0F826;
  --button-primary-normal-background-color: #C0F826;
  --button-primary-hover-background-color: #D5FA6E;
  --button-primary-pressed-background-color: #E0FC93;
  --button-primary-disabled-background-color: #ECEFF6;
  --button-icon-color: #FFF;
  --ant-btn-border-radius: 0;
  --ant-btn-primary-background: #C0F826;
  --ant-btn-primary-before-background: #D5FA6E;
  --ant-btn-color: #110D3D;
  --ant-btn-hover-color: #110D3D;
  --ant-btn-border-color: #C0F826;
  --side-menu-background-color: #1e1e21;
  --side-menu-menu-item-text-color: #FFF;
  --side-menu-active-menu-item-text-color: #E1E0E4;
  --side-menu-active-menu-item-background: linear-gradient(117deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.01) 100%);
  --account-manager-contact-background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.18) -267.85%, rgba(255, 255, 255, 0) 138.29%);
  --account-manager-title-color: #FFF;
  --modal-header-color: rgba(32, 32, 35, 0.87);
  --modal-text: rgba(255, 255, 255, 0.85);
  --modal-subtitle: rgba(255, 255, 255, 0.8);
  --modal-body: #141417;
  --modal-input-background: #202023;
  --tab-active-background-color: #C0F826;
  --tab-active-text-color: #110D3D;
  --tab-inactive-text-color: #FFF;
  --tab-hover-text-color: #FFF;
  --table-border-radius: 0;
  --cell-border-radius: 0;
  --cell-hover-border-radius: 0;
  --button-border-radius: 0;
  --select-border-radius: 0;
  --bg-wrapper-border-radius: 0;
  --spin-border-radius: 0;
  --pagination-item-active-background: #C0F826;
  --pagination-border-radius: 0;
  --pagination-item-active-border-radius: 0;
  --pagination-item-text-color: #FFF;
  --pagination-item-active-text-color: #110D3D;
  --pagination-showing-number-items: #9EA1B9;
  --pagination-show-more-arrows-color: #FFF;
  --checkbox-border-radius: 0;
  --checkbox-border: #1C1B1F;
  --checkbox-border-secondary: #C0F826;
  --checkbox-select-background: #C0F826;
  --checkbox-label-color: #FFF;
  --switch-background: #C0F826;
  --switch-btn-disabled-color: #EFFAD0;
  --search-border-radius: 0;
  --input-border-radius: 0;
  --input-background: rgba(255, 255, 255, 0.05);
  --input-placeholder-text-color: rgba(255, 255, 255, 0.3);
  --date-picker-border-radius: 0;
  --datepicker-icon-background: #C0F826;
  --datepicker-icon-color: #1C1B1F;
  --datepicker-cell-selected: #C0F826;
  --datepicker-cell-selected-text-color: #110D3D;
  --datepicker-range-line-backgorund: #EAFDB7;
  --datepicker-range-line-text-color: #9EA1B9;
  --ant-slider-rail-background: #D5FA6E;
  --slider-background: #C0F826;
  --spinner-fill-color: #C0F826;
  --loader-secondary-color: rgba(55, 53, 61, 0.05);
  --loader-main-color: #C0F826;
  --card-border-radius: 0;
  --menu-border-radius: 0;
  --activity-border-radius: 0;
  --activity-rise-color: #80CA8A;
  --activity-rise-background-color: rgba(128, 202, 138, 0.1);
  --activity-down-color: #FE6B6B;
  --activity-down-background-color: rgba(254, 107, 107, 0.1);
  --icon-perfomance-border-radius: 5px;
  --menu-icon-fill: #E1E0E4;
  --menu-selected-icon-fill: #E1E0E4;
  --ant-icon-fill-color: #1C1B1F;
  --icon-search-color: #FFF;
  --icon-check-background: #C0F826;
  --icon-check-color: #1C1B1F;
  --icon-check-border-radius: 0;
  --top-partners-background-color: #EFFAD0;
  --top-partners-text-main-color: #FFF;
  --top-partners-text-secondary-color: rgba(255, 255, 255, 0.85);
  --top-partners-text-main-color-hover: rgba(255, 255, 255, 0.85);
  --top-partners-thed-border-bottom: #C0F826;
  --top-partners-row-hover: #D5FA6E;
  --client-status-border-radius: 0;
  --balance-background: #EFFAD0;
  --balance-text-color: #110D3D;
  --balance-icon-color: #110D3D;
  --my-commission-background-card: linear-gradient(71.54deg, #C0F826 13.69%, #EFFAD0 79.74%);
  --my-commission-value-color: #110D3D;
  --my-commission-title-color: rgba(17, 13, 61, 0.5);
  --my-commission-result-color: #110D3D;
  --my-commission-changes-color: #110D3D;
  --top-clients-main-color: #110D3D;
  --top-clients-secondary-color: #110D3D;
  --profile-settings-icons-color: #C0F826;
  --partners-profile-arrow-back: #FFF;
  --tile-background-color: linear-gradient(131.94deg, rgba(255, 255, 255, 0.08) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --tile-text-color: #828282;
  --tile-positive-change-color: #80CA8A;
  --tile-negative-change-color: #FE6B6B;
  --ant-select-country-text-color: #110D3D;
  --ant-select-country-icon-color: #1C1B1F;
  --ant-select-country-background-color: #EFFAD0;
  --ant-badge-color: #110D3D;
  --ant-badge-backround-color: #EFFAD0;
  --ant-badge-border-radius: 0;
  --ant-message-loading: #C0F826;
  --ant-message-success: #80CA8A;
  --ant-message-text-loading: #110D3D;
  --ant-message-anticon-loading: #FFF;
  --link-color: rgba(255, 255, 255, 0.85);
  --link-hover-color: #FFF;
}
[data-brand="DAY1"][data-theme="light"] {
  --text-color: #323232;
  --primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --button-text-color: #FFF;
  --button-icon-color: #1C1B1F;
  --button-border-radius: 10px;
  --ant-btn-border-radius: 4px;
  --ant-btn-primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-btn-primary-before-background: linear-gradient(90deg, #1811A2, #742CCE);
  --ant-btn-color: #FFF;
  --ant-btn-hover-color: #712BCD;
  --ant-btn-border-color: #712BCD;
  --button-outline-text-color: #1811A2;
  --button-outline-border-color: #1811A2;
  --button-primary-normal-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --button-primary-hover-background-color: linear-gradient(90deg, #1811A2, #742CCE);
  --button-primary-pressed-background-color: #1811A2;
  --button-primary-disabled-background-color: rgba(255, 255, 255, 0.7);
  --side-menu-background-color: #FFF;
  --side-menu-menu-item-text-color: #8f8f8f;
  --side-menu-active-menu-item-text-color: #2ccdcd;
  --side-menu-active-menu-item-background: rgba(51, 51, 51, 0.05);
  --account-manager-contact-background: #F6F6F6;
  --account-manager-title-color: black;
  --modal-header-color: rgba(246, 246, 246, 0.94);
  --modal-text: #202020;
  --modal-subtitle: rgba(32, 32, 32, 0.5);
  --modal-body: #FFF;
  --modal-header-border: #d9d9d9;
  --modal-input-background: #F6F6F6;
  --tab-active-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --tab-active-text-color: #FFF;
  --tab-inactive-text-color: #323232;
  --tab-hover-text-color: #323232;
  --table-border-radius: 12px;
  --table-row-selected-background-color: #F6F6F6;
  --cell-border-radius: 10px;
  --cell-hover-border-radius: 12px;
  --select-border-radius: 10px;
  --bg-wrapper-border-radius: 6px;
  --spin-border-radius: 12px;
  --pagination-item-active-background: linear-gradient(90deg, #742CCE, #1811A2);
  --pagination-border-radius: 10px;
  --pagination-item-active-border-radius: 12px;
  --pagination-item-text-color: #323232;
  --pagination-item-active-text-color: #FFF;
  --pagination-showing-number-items: #7be0f4;
  --pagination-show-more-arrows-color: #2CCDCD;
  --checkbox-border-radius: 5px;
  --checkbox-border: #8B8B8B;
  --checkbox-border-secondary: #2CCDCD;
  --checkbox-select-background: #2CCDCD;
  --checkbox-label-color: #202020;
  --switch-background: #9A60E8;
  --switch-btn-disabled-color: #919191;
  --search-border-radius: 12px;
  --input-border-radius: 10px;
  --input-background: #F6F6F6;
  --input-placeholder-text-color: rgba(32, 32, 32, 0.5);
  --date-picker-border-radius: 10px;
  --datepicker-icon-background: linear-gradient(90deg, #742CCE, #1811A2);
  --datepicker-icon-color: #FFF;
  --datepicker-cell-selected: #2CCDCD;
  --datepicker-cell-selected-text-color: #FFF;
  --datepicker-range-line-backgorund: rgba(44, 205, 205, 0.2);
  --datepicker-range-line-text-color: #323232;
  --ant-slider-rail-background: rgba(44, 205, 205, 0.2);
  --slider-background: #2CCDCD;
  --spinner-fill-color: #2CCDCD;
  --loader-secondary-color: rgba(51, 51, 51, 0.05);
  --loader-main-color: #2CCDCD;
  --card-border-radius: 12px;
  --menu-border-radius: 10px;
  --activity-border-radius: 12px;
  --activity-rise-color: #51B56D;
  --activity-rise-background-color: rgba(81, 181, 109, 0.1);
  --activity-down-color: #E14449;
  --activity-down-background-color: rgba(225, 68, 73, 0.1);
  --icon-perfomance-border-radius: 5px;
  --menu-icon-fill: #8f8f8f;
  --menu-selected-icon-fill: #2CCDCD;
  --ant-icon-fill-color: #FFF;
  --icon-search-color: #202020;
  --icon-check-background: linear-gradient(90deg, #742CCE, #1811A2);
  --icon-check-color: #FFF;
  --icon-check-border-radius: 12px;
  --top-partners-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --top-partners-text-main-color: #FFF;
  --top-partners-text-secondary-color: #8B8B8B;
  --top-partners-text-main-color-hover: rgba(255, 255, 255, 0.15);
  --top-partners-thed-border-bottom: rgba(255, 255, 255, 0.1);
  --top-partners-row-hover: linear-gradient(131.94deg, rgba(255, 255, 255, 0.1) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --client-status-border-radius: 6px;
  --balance-background: rgba(44, 205, 205, 0.1);
  --balance-text-color: #2CCDCD;
  --balance-icon-color: #2CCDCD;
  --my-commission-background-card: url("./assets/mask-group.png"), linear-gradient(180deg, #732DCE 0%, #1B12A3 100%);
  --my-commission-value-color: #FFF;
  --my-commission-title-color: rgba(255, 255, 255, 0.6);
  --my-commission-result-color: rgba(255, 255, 255, 0.6);
  --my-commission-changes-color: #FFF;
  --top-clients-main-color: #FFF;
  --top-clients-secondary-color: rgba(255, 255, 255, 0.5);
  --profile-settings-icons-color: #985EFF;
  --partners-profile-arrow-back: #2CCDCD;
  --tile-background-color: #F6F6F6;
  --tile-text-color: #8f8f8f;
  --tile-positive-change-color: #51B56D;
  --tile-negative-change-color: #E14449;
  --ant-select-country-text-color: #FFF;
  --ant-select-country-icon-color: #FFF;
  --ant-select-country-background-color: #2CCDCD;
  --ant-badge-color: #FFF;
  --ant-badge-backround-color: #2CCDCD;
  --ant-badge-border-radius: 10px;
  --ant-message-loading: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-message-success: #51B56D;
  --ant-message-text-loading: #FFF;
  --ant-message-anticon-loading: #1890ff;
  --link-color: #2CCDCD;
  --link-hover-color: #299F9F;
}
[data-brand="DAY1"][data-theme="dark"] {
  --text-color: #FFF;
  --primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --button-text-color: #FFF;
  --button-icon-color: #FFF;
  --button-border-radius: 10px;
  --ant-btn-border-radius: 4px;
  --ant-btn-primary-background: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-btn-primary-before-background: linear-gradient(90deg, #1811A2, #742CCE);
  --ant-btn-color: #FFF;
  --ant-btn-hover-color: #712BCD;
  --ant-btn-border-color: #712BCD;
  --button-outline-text-color: #1811A2;
  --button-outline-border-color: #1811A2;
  --button-primary-normal-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --button-primary-hover-background-color: linear-gradient(90deg, #1811A2, #742CCE);
  --button-primary-pressed-background-color: #1811A2;
  --button-primary-disabled-background-color: rgba(255, 255, 255, 0.7);
  --side-menu-background-color: #1e1e21;
  --side-menu-menu-item-text-color: rgba(255, 255, 255, 0.5);
  --side-menu-active-menu-item-text-color: #FFF;
  --side-menu-active-menu-item-background: rgba(255, 255, 255, 0.05);
  --account-manager-contact-background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.18) -267.85%, rgba(255, 255, 255, 0) 138.29%);
  --account-manager-title-color: #FFF;
  --modal-header-color: rgba(32, 32, 35, 0.87);
  --modal-text: rgba(255, 255, 255, 0.85);
  --modal-subtitle: rgba(255, 255, 255, 0.8);
  --modal-body: #141417;
  --modal-input-background: #202023;
  --tab-active-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --tab-active-text-color: #FFF;
  --tab-inactive-text-color: #FFF;
  --tab-hover-text-color: #FFF;
  --table-border-radius: 12px;
  --table-row-selected-background-color: #2e2e31;
  --cell-border-radius: 10px;
  --cell-hover-border-radius: 12px;
  --select-border-radius: 10px;
  --bg-wrapper-border-radius: 6px;
  --spin-border-radius: 12px;
  --pagination-item-active-background: linear-gradient(90deg, #742CCE, #1811A2);
  --pagination-border-radius: 10px;
  --pagination-item-active-border-radius: 12px;
  --pagination-item-text-color: #FFF;
  --pagination-item-active-text-color: #FFF;
  --pagination-showing-number-items: #29b6b6;
  --pagination-show-more-arrows-color: #2CCDCD;
  --checkbox-border-radius: 5px;
  --checkbox-border: #8B8B8B;
  --checkbox-border-secondary: #2CCDCD;
  --checkbox-select-background: #2CCDCD;
  --checkbox-label-color: #FFF;
  --switch-background: #9A60E8;
  --switch-btn-disabled-color: #919191;
  --search-border-radius: 12px;
  --input-border-radius: 10px;
  --input-background: rgba(255, 255, 255, 0.05);
  --input-placeholder-text-color: rgba(255, 255, 255, 0.3);
  --date-picker-border-radius: 10px;
  --datepicker-icon-background: linear-gradient(90deg, #742CCE, #1811A2);
  --datepicker-icon-color: #FFF;
  --datepicker-cell-selected: #2CCDCD;
  --datepicker-cell-selected-text-color: #FFF;
  --datepicker-range-line-backgorund: rgba(44, 205, 205, 0.2);
  --datepicker-range-line-text-color: #FFF;
  --ant-slider-rail-background: rgba(44, 205, 205, 0.2);
  --slider-background: #2CCDCD;
  --spinner-fill-color: #8B8B8B;
  --loader-secondary-color: rgba(255, 255, 255, 0.05);
  --loader-main-color: #8B8B8B;
  --card-border-radius: 12px;
  --menu-border-radius: 10px;
  --activity-border-radius: 12px;
  --activity-rise-color: #51B56D;
  --activity-rise-background-color: rgba(81, 181, 109, 0.1);
  --activity-down-color: #E14449;
  --activity-down-background-color: rgba(225, 68, 73, 0.1);
  --icon-perfomance-border-radius: 5px;
  --menu-icon-fill: #8f8f8f;
  --menu-selected-icon-fill: #FFF;
  --ant-icon-fill-color: #FFF;
  --icon-search-color: #FFF;
  --icon-check-background: linear-gradient(90deg, #742CCE, #1811A2);
  --icon-check-color: #FFF;
  --icon-check-border-radius: 12px;
  --top-partners-background-color: linear-gradient(90deg, #742CCE, #1811A2);
  --top-partners-text-main-color: #FFF;
  --top-partners-text-secondary-color: rgba(255, 255, 255, 0.85);
  --top-partners-text-main-color-hover: rgba(255, 255, 255, 0.85);
  --top-partners-thed-border-bottom: rgba(255, 255, 255, 0.1);
  --top-partners-row-hover: linear-gradient(131.94deg, rgba(255, 255, 255, 0.1) -22.88%, rgba(255, 255, 255, 0.01) 91.76%);
  --client-status-border-radius: 6px;
  --balance-background: rgba(44, 205, 205, 0.1);
  --balance-text-color: #2CCDCD;
  --balance-icon-color: #2CCDCD;
  --my-commission-background-card: url("./assets/mask-group.png"), linear-gradient(180deg, #732DCE 0%, #1B12A3 100%);
  --my-commission-value-color: #FFF;
  --my-commission-title-color: rgba(255, 255, 255, 0.6);
  --my-commission-result-color: rgba(255, 255, 255, 0.6);
  --my-commission-changes-color: #FFF;
  --top-clients-main-color: #FFF;
  --top-clients-secondary-color: rgba(255, 255, 255, 0.5);
  --profile-settings-icons-color: #985EFF;
  --partners-profile-arrow-back: #FFF;
  --tile-background-color: linear-gradient(125.86deg, rgba(255, 255, 255, 0.2) -267.85%, rgba(255, 255, 255, 0) 138.29%);
  --tile-text-color: rgba(255, 255, 255, 0.5);
  --tile-positive-change-color: #51B56D;
  --tile-negative-change-color: #E14449;
  --ant-select-country-text-color: #FFF;
  --ant-select-country-icon-color: #FFF;
  --ant-select-country-background-color: #2CCDCD;
  --ant-badge-color: #FFF;
  --ant-badge-backround-color: #2CCDCD;
  --ant-badge-border-radius: 10px;
  --ant-message-loading: linear-gradient(90deg, #742CCE, #1811A2);
  --ant-message-success: #51B56D;
  --ant-message-text-loading: #FFF;
  --ant-message-anticon-loading: #1890ff;
  --link-color: rgba(255, 255, 255, 0.85);
  --link-hover-color: #FFF;
}
a,
a.ant-typography,
.ant-typography a {
  color: #2CCDCD;
}
a:hover,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #299F9F;
}
.ant-typography pre {
  padding: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: transparent;
  border: none;
  border-radius: 0;
  color: var(--text-secondary);
  font-family: 'Cairo', -apple-system, BlinkMacSystemFont, sans-serif;
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--text-color);
}
h1.ant-typography {
  font-size: 32px;
}
h2.ant-typography {
  font-size: 18px;
}
h3.ant-typography {
  font-size: 16px;
}
h4.ant-typography {
  font-size: 15px;
}
h5.ant-typography {
  font-size: 14px;
}
.ant-popover {
  padding: 0;
}
.ant-popover-arrow {
  display: none;
}
.ant-popover-inner-content {
  padding: 0;
  background: var(--transparent-gradient);
  border-radius: 10px;
}
.ant-popover-inner {
  background: var(--layout-color);
  border-radius: 10px;
}
.ant-tooltip.ant-slider-tooltip {
  min-width: 50px;
}
.ant-tooltip.ant-slider-tooltip .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background-color: var(--tooltip);
}
.ant-tooltip.ant-slider-tooltip .ant-tooltip-content .ant-tooltip-inner {
  min-height: auto;
  padding: 4px 8px;
  color: var(--text-color);
  text-align: center;
  background: var(--tooltip);
  backdrop-filter: saturate(180%) blur(11px);
  border-radius: 10px;
}
.ant-select-dropdown {
  background: var(--select-dropdown-background);
}
.ant-select-item.ant-select-item-option {
  background: var(--select-dropdown-background);
}
.ant-select-item.ant-select-item-option {
  color: var(--text-color);
}
.rc-virtual-list-holder-inner .ant-select-item.ant-select-item-option:hover {
  background: var(--dropdown-menu-hover);
}
.ant-message {
  position: absolute;
  bottom: 20px !important;
  top: unset !important;
}
.ant-message .ant-message-notice-content {
  padding: 0;
  background: none;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-message-custom-content {
  width: 500px;
  padding: 10px 20px;
  border-radius: 10px;
  letter-spacing: -0.02em;
  color: #FFF;
}
.ant-message-success {
  background: var(--ant-message-success);
}
.ant-message-success .anticon {
  color: #FFF !important;
}
.ant-message-loading {
  background: var(--ant-message-loading);
  color: var(--ant-message-text-loading);
}
.ant-message-loading .anticon {
  color: var(--ant-message-anticon-loading) !important;
}
.ant-message-error {
  background: #E14449;
}
.ant-message-error .anticon {
  color: #FFF !important;
}
@media (max-width: 575.98px) {
  .ant-message-custom-content {
    width: 280px;
    padding: 10px 16px;
  }
}
.ant-spin-container:after {
  background: transparent;
}
.ant-notification-notice {
  background: var(--notification-modal-backgound);
  color: var(--notification-modal-color);
}
.ant-notification-notice-message {
  color: var(--notification-modal-color);
}
.ant-notification-notice p {
  margin-top: 5px;
  margin-bottom: 0;
}
.ant-notification-close-x svg {
  fill: var(--notification-modal-color);
}
#root {
  height: 100%;
}
html {
  height: unset;
}
body {
  margin: 0;
  background-color: var(--layout-color);
  font-family: 'Cairo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-modal-confirm .ant-modal-body {
  background: var(--notification-modal-backgound);
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  color: var(--notification-modal-color);
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  border-radius: var(--ant-btn-border-radius);
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn:hover {
  color: var(--ant-btn-hover-color);
  border-color: var(--ant-btn-border-color);
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn-primary {
  background: var(--ant-btn-primary-background);
  color: var(--ant-btn-color);
  border: none;
  z-index: 1;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn-primary:hover {
  color: var(--ant-btn-color);
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn-primary:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--ant-btn-primary-before-background);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn-primary:hover:before {
  opacity: 1;
}
.tooltip-wrapper .ant-tooltip-content .ant-tooltip-arrow {
  display: none;
}
.tooltip-wrapper .ant-tooltip-content .ant-tooltip-inner {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--text-color);
  background: var(--tooltip-background);
  padding: 10px 23px 10px 16px;
}
