.calendar-typography {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
}
.side-menu-item {
  font-size: 16px;
  border-radius: var(--menu-border-radius);
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:after {
  content: none !important;
}
.side-menu-item:active {
  background: transparent;
}
.side-menu-item-icon {
  line-height: 1;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item .side-menu-item-text {
  text-transform: capitalize;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:hover .side-menu-item-icon,
.side-menu-item:hover .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global(.ant-menu-item-selected) {
  background: var(--side-menu-active-menu-item-background);
}
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-icon,
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global.ant-menu-item {
  height: 45px;
  padding: 6px 16px;
}
.side-menu-item:global.ant-menu-item:not(:last-child) {
  margin: 0 0 16px;
}
.side-menu-item:global.ant-menu-item:last-child {
  margin: 0;
}
.disabled-button[disabled],
.disabled-button[disabled]:hover,
.disabled-button[disabled]:focus,
.disabled-button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.select-option {
  align-items: center;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--text-color);
}
.select-option:hover {
  background-color: var(--dropdown-menu-hover);
}
.select-option:global.ant-select-item-option-selected,
.select-option:global.ant-select-item-option-active {
  background-color: var(--dropdown-menu-hover);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.pagination .ant-pagination-next svg {
  transform: rotate(180deg);
}
.pagination .ant-pagination-prev,
.pagination .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.pagination .ant-pagination-prev svg:hover,
.pagination .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-prev svg path,
.pagination .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.pagination .ant-pagination-prev.ant-pagination-disabled svg,
.pagination .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.pagination .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.pagination .ant-pagination-item:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.pagination .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.pagination .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.pagination .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.pagination .ant-pagination-item-active:hover {
  opacity: 1;
}
.pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.pagination .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.pagination .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus {
  font-size: 14px !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color) !important;
  background: transparent !important;
  caret-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.default-table-padding .ant-table table .ant-table-cell {
  padding: 9px 16px 9px 6px;
}
.default-table-padding .ant-table table .ant-table-cell:first-child {
  padding: 9px 16px 10px 9px;
}
.table-nowrap .ant-table-tbody > tr > td,
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.auto-complete {
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}
.auto-complete :global .rc-virtual-list .rc-virtual-list-holder {
  overflow-y: auto;
  scrollbar-width: thin;
}
.auto-complete :global .rc-virtual-list .rc-virtual-list-holder::-webkit-scrollbar {
  width: 5px;
}
.auto-complete :global .rc-virtual-list .rc-virtual-list-holder::-webkit-scrollbar-track {
  margin: 16px;
  border-radius: 10px;
  background: var(--button-dissable);
}
.auto-complete :global .rc-virtual-list .rc-virtual-list-holder::-webkit-scrollbar-thumb {
  background: var(--scrollbar-handle);
  border-radius: 10px;
}
.auto-complete :global .ant-select-item {
  height: 41px;
  padding: 10px 16px;
  border-radius: var(--menu-border-radius);
}
.auto-complete :global .ant-select-item.ant-select-item-option {
  background: var(--interactive-widget);
}
.auto-complete :global .ant-select-item.ant-select-item-option-disabled > div {
  opacity: 0.5;
}
.auto-complete-values {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.02em;
}
.auto-complete-values .searched-value {
  color: var(--modal-text);
}
.auto-complete-values .suggested-value {
  color: #2CCDCD;
}
.auto-complete-values .default-content {
  color: var(--text-secondary);
}
.auto-complete-values .value-indent {
  margin-left: 20px;
  text-align: right;
}
.auto-complete-values .swift-value {
  margin-left: 10px;
}
.auto-complete .position-start {
  justify-content: start;
}
.auto-complete .skeleton {
  margin: 2px 0;
}
.auto-complete .skeleton :global .ant-skeleton-paragraph {
  margin: auto 0;
}
.auto-complete .skeleton :global .ant-skeleton-paragraph > li {
  width: 100% !important;
}
.input:global.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 16px;
  right: 16px;
  top: unset;
  bottom: unset;
  line-height: 20px;
}
.input:global.ant-select-single .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  height: 20px;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--text-color);
  cursor: text;
}
.input:global.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 41px;
  width: 100%;
  padding: 10.5px 16px;
  background: var(--modal-input-background);
  border-radius: var(--input-border-radius);
  border: 1px solid transparent !important;
  box-shadow: none;
}
.input:global.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.input:global.ant-select:not(.ant-select-customize-input) .ant-select-selector:active,
.input:global.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
.input:global.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus-within {
  border: 1px solid var(--input-focus) !important;
  box-shadow: none;
}
.input:global.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--input-placeholder-text-color);
}
.white:global.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white !important;
}
.search {
  width: 100%;
  background: var(--layout-color);
  border-radius: 10px;
}
.search:global.ant-select-single .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  height: 41px;
}
.partner :global .ant-select-item {
  height: 60px;
}
.spinner {
  height: 38px;
}
