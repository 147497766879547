.calendar-typography {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
}
.side-menu-item {
  font-size: 16px;
  border-radius: var(--menu-border-radius);
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:after {
  content: none !important;
}
.side-menu-item:active {
  background: transparent;
}
.side-menu-item-icon {
  line-height: 1;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item .side-menu-item-text {
  text-transform: capitalize;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:hover .side-menu-item-icon,
.side-menu-item:hover .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global(.ant-menu-item-selected) {
  background: var(--side-menu-active-menu-item-background);
}
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-icon,
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global.ant-menu-item {
  height: 45px;
  padding: 6px 16px;
}
.side-menu-item:global.ant-menu-item:not(:last-child) {
  margin: 0 0 16px;
}
.side-menu-item:global.ant-menu-item:last-child {
  margin: 0;
}
.disabled-button[disabled],
.disabled-button[disabled]:hover,
.disabled-button[disabled]:focus,
.disabled-button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.select-option {
  align-items: center;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--text-color);
}
.select-option:hover {
  background-color: var(--dropdown-menu-hover);
}
.select-option:global.ant-select-item-option-selected,
.select-option:global.ant-select-item-option-active {
  background-color: var(--dropdown-menu-hover);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.pagination .ant-pagination-next svg {
  transform: rotate(180deg);
}
.pagination .ant-pagination-prev,
.pagination .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.pagination .ant-pagination-prev svg:hover,
.pagination .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-prev svg path,
.pagination .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.pagination .ant-pagination-prev.ant-pagination-disabled svg,
.pagination .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.pagination .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.pagination .ant-pagination-item:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.pagination .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.pagination .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.pagination .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.pagination .ant-pagination-item-active:hover {
  opacity: 1;
}
.pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.pagination .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.pagination .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus {
  font-size: 14px !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color) !important;
  background: transparent !important;
  caret-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.default-table-padding .ant-table table .ant-table-cell {
  padding: 9px 16px 9px 6px;
}
.default-table-padding .ant-table table .ant-table-cell:first-child {
  padding: 9px 16px 10px 9px;
}
.table-nowrap .ant-table-tbody > tr > td,
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.first-and-last-cell {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  width: calc(100% - 10px);
  height: 1px;
  border-bottom: 1px solid var(--table-thed-border);
}
.table:global.ant-table-wrapper .ant-spin-nested-loading .ant-spin-spinning {
  max-height: 100%;
}
.table:global.ant-table-wrapper .ant-spin-nested-loading .ant-spin-blur {
  border-radius: var(--spin-border-radius);
}
.table :global .ant-table-title {
  padding: 24px 24px 10px;
}
.table :global .ant-table {
  border-radius: var(--table-border-radius);
  color: var(--text-color);
  background: var(--transparent-gradient);
}
.table :global .ant-table .ant-typography {
  color: var(--text-color);
}
.table :global .ant-table .ant-table-selection-column {
  text-align: left;
}
.table :global .ant-table .ant-table-row {
  cursor: pointer;
}
.table :global .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.table :global .ant-pagination-next svg {
  transform: rotate(180deg);
}
.table :global .ant-pagination-prev,
.table :global .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.table :global .ant-pagination-prev svg:hover,
.table :global .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.table :global .ant-pagination-prev svg path,
.table :global .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.table :global .ant-pagination-prev.ant-pagination-disabled svg,
.table :global .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.table :global .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.table :global .ant-pagination-item:hover {
  opacity: 0.4;
}
.table :global .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.table :global .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.table :global .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.table :global .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.table :global .ant-pagination-item-active:hover {
  opacity: 1;
}
.table :global .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.table :global .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.table :global .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.table :global .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.table :global .ant-table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 24px 24px;
  background: transparent;
}
.table :global .ant-table-summary {
  background: var(--table-summary-bg);
}
.table :global .ant-table-tbody .ant-table-cell {
  border: none;
}
.table :global .ant-table-tbody > tr.ant-table-row:hover {
  background: var(--table-row-hover);
}
.table :global .ant-table-tbody > tr.ant-table-row:hover td {
  background: transparent;
}
.table :global .ant-table-tbody > tr.ant-table-row:hover td:first-child {
  border-top-left-radius: var(--cell-hover-border-radius);
  border-bottom-left-radius: var(--cell-hover-border-radius);
}
.table :global .ant-table-tbody > tr.ant-table-row:hover td:last-child {
  border-top-right-radius: var(--cell-hover-border-radius);
  border-bottom-right-radius: var(--cell-hover-border-radius);
}
.table :global .ant-table-container {
  min-height: 200px;
  overflow-x: scroll;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-handle) transparent;
}
.table :global .ant-table-container::-webkit-scrollbar {
  height: 2px;
}
.table :global .ant-table-container::-webkit-scrollbar-track {
  margin: 24px;
  border-radius: 60px;
}
@media (max-width: 575.98px) {
  .table :global .ant-table-container::-webkit-scrollbar-track {
    margin: 16px;
  }
}
.table :global .ant-table-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-handle);
  border-radius: 10px;
}
.table :global .ant-table-container .ant-table-content > table {
  padding: 0 14px;
}
@media (max-width: 991.98px) {
  .table :global .ant-table-title {
    padding: 24px 16px 16px;
  }
  .table :global .ant-table-footer {
    padding: 0 16px 24px;
  }
  .table :global .ant-table-container .ant-table-content > table {
    padding: 0 6px;
  }
}
.table :global .ant-table-tbody > tr.ant-table-row-selected > td {
  background: var(--table-row-selected-background-color);
}
.table :global .ant-table-tbody > tr.ant-table-row-selected > td:first-child {
  border-top-left-radius: var(--cell-hover-border-radius);
  border-bottom-left-radius: var(--cell-hover-border-radius);
}
.table :global .ant-table-tbody > tr.ant-table-row-selected > td:last-child {
  border-top-right-radius: var(--cell-hover-border-radius);
  border-bottom-right-radius: var(--cell-hover-border-radius);
}
.table :global .ant-table table {
  border-spacing: 0 16px;
}
.table :global .ant-table table .ant-checkbox-inner:after {
  display: none;
}
.table :global .ant-table table .ant-checkbox {
  margin-top: 1px;
  border-radius: 4px;
}
.table :global .ant-table table .ant-checkbox .ant-checkbox-inner {
  background: var(--term-color);
  border: 2px solid var(--checkbox-border);
  border-radius: var(--checkbox-border-radius);
}
.table :global .ant-table table .ant-checkbox.ant-checkbox-checked {
  margin-right: 0;
  border-radius: 6px;
}
.table :global .ant-table table .ant-checkbox.ant-checkbox-checked::after {
  border: none;
}
.table :global .ant-table table .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--checkbox-select-background);
  border: 2px solid var(--term-border-active);
  box-shadow: 0 0 0 2px var(--checkbox-select-background);
}
.table :global .ant-table table .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
  display: none;
}
.table :global .ant-table table .ant-table-cell {
  padding: 2px 2px 2px 6px;
}
.table :global .ant-table table .ant-table-cell:before {
  display: none;
}
.table :global .ant-table table .ant-table-cell:first-child {
  padding: 2px 2px 2px 10px;
}
.table :global .ant-table table .ant-table-cell label {
  border-spacing: 0 !important;
}
.table :global .ant-table table .ant-table-thead > tr > th {
  white-space: nowrap;
  user-select: none;
}
.table :global .ant-table table .ant-table-thead > tr > th .ant-table-column-sorters .ant-table-column-title {
  flex-grow: 0;
}
.table :global .ant-table table .ant-table-thead > tr > th .ant-table-column-sorters .ant-table-column-sorter {
  flex-grow: 1;
  margin-left: 5px;
}
.table :global .ant-table table .ant-table-thead > tr > th .ant-table-column-sorters .ant-table-column-sorter-inner .active {
  color: var(--text-color);
}
.table :global .ant-table table .ant-table-thead > tr > th .ant-table-column-sorters .anticon {
  color: #FFF;
}
.table :global .ant-table table .ant-table-thead > tr > th .ant-table-column-sorters .anticon:not(.active) {
  color: var(--text-secondary);
}
.table :global .ant-table table .ant-table-thead .ant-table-selection-column::after {
  background-color: var(--table-thed-border) !important;
}
.table :global .ant-table table .ant-table-thead .ant-table-cell {
  padding: 0 10px 8px 6px;
  border-bottom: 1px solid var(--table-thed-border);
  font-size: 12px;
  color: var(--top-partners-text-secondary-color);
  text-transform: capitalize;
  transition: none;
  background: transparent !important;
}
.table :global .ant-table table .ant-table-thead .ant-table-cell::before {
  width: 0;
}
.table :global .ant-table table .ant-table-thead .ant-table-cell:first-child {
  padding: 0 10px 9px 10px !important;
  border: none !important;
}
.table :global .ant-table table .ant-table-thead .ant-table-cell:first-child:after {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  width: calc(100% - 10px);
  height: 1px;
  border-bottom: 1px solid var(--table-thed-border);
}
.table :global .ant-table table .ant-table-thead .ant-table-cell:last-child {
  padding: 0 10px 9px 0 !important;
  border: none !important;
}
.table :global .ant-table table .ant-table-thead .ant-table-cell:last-child:after {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  width: calc(100% - 10px);
  height: 1px;
  border-bottom: 1px solid var(--table-thed-border);
}
.table :global .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}
.table :global .ant-table-row td {
  cursor: pointer;
}
.table :global .ant-table-row td:first-child {
  border-top-left-radius: var(--cell-hover-border-radius);
  border-bottom-left-radius: var(--cell-hover-border-radius);
}
.table :global .ant-table-row td:last-child {
  border-top-right-radius: var(--cell-hover-border-radius);
  border-bottom-right-radius: var(--cell-hover-border-radius);
}
.table :global .ant-table-row td.ant-table-column-sort {
  background-color: transparent;
}
.table :global .ant-empty.ant-empty-normal .ant-empty-description {
  color: var(--text-secondary);
}
@media (max-width: 991.98px) {
  .table :global .ant-table-footer .ant-typography:nth-child(2) {
    margin: 24px 0 0;
    text-align: center;
    width: 100%;
  }
}
.table .no-data {
  min-height: 240px;
  display: flex;
}
.table .no-data div {
  margin: auto;
}
.table .no-data div p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--top-clients-secondary-color);
  padding-top: 16px;
}
.table .no-data .black-text {
  color: var(--no-data-text);
}
.tile div p {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.02em;
  color: var(--no-data-text);
}
.top-dashboard :global [data-table-header='title'] {
  color: var(--top-partners-text-main-color);
}
.top-dashboard :global [data-table-header='link'] > span {
  color: var(--top-partners-text-main-color);
}
.top-dashboard :global [data-table-header='link']:hover {
  background: var(--top-partners-text-main-color-hover);
}
.top-dashboard :global [data-table-header='link']:hover > span {
  color: var(--top-partners-text-main-color);
}
.top-dashboard :global .ant-table-footer {
  display: none !important;
}
.top-dashboard :global .ant-table table .ant-table-row {
  transform: translate(0px, 5px);
}
.top-dashboard :global .ant-table table .ant-table-cell {
  padding: 5px 10px !important;
}
.top-dashboard :global .ant-table table .ant-table-thead .ant-table-cell {
  padding: 0 10px 8px 10px !important;
}
.top-dashboard :global .ant-table-title .ant-typography {
  color: var(--top-partners-text-main-color) !important;
}
.top-dashboard :global .ant-table {
  min-height: 310px;
  background: var(--top-partners-background-color) !important;
  color: var(--top-partners-text-main-color) !important;
}
.top-dashboard :global .ant-table table {
  border-spacing: 0 8px;
}
.top-dashboard :global .ant-table-wrapper {
  margin: 0;
}
.top-dashboard :global .ant-table-tbody > tr.ant-table-row:hover {
  background: var(--top-partners-row-hover) !important;
}
.top-dashboard :global .ant-table-tbody > .ant-table-cell {
  background: var(--top-partners-row-hover) !important;
}
.top-dashboard :global .ant-table-thead .ant-table-cell {
  border-color: var(--top-partners-thed-border-bottom) !important;
}
.top-dashboard :global .ant-table-thead .ant-table-cell:last-child:after,
.top-dashboard :global .ant-table-thead .ant-table-cell:first-child:after {
  border-bottom: 1px solid var(--top-partners-thed-border-bottom) !important;
}
.top-dashboard :global .ant-table-tbody > tr > td:nth-child(1),
.top-dashboard :global .ant-table-thead > tr > th:nth-child(1) {
  min-width: 100px;
}
.top-dashboard :global .ant-table-tbody > tr > td:nth-child(2),
.top-dashboard :global .ant-table-thead > tr > th:nth-child(2) {
  min-width: 60px;
}
.top-dashboard :global .ant-table-tbody > tr > td:nth-child(3),
.top-dashboard :global .ant-table-thead > tr > th:nth-child(3) {
  min-width: 100px;
  text-align: right;
}
.payment-history :global .ant-table-title,
.balance-history :global .ant-table-title {
  display: none;
}
.clients :global .ant-table-tbody > tr > td:nth-child(1),
.clients :global .ant-table-thead > tr > th:nth-child(1) {
  min-width: 60px;
}
.clients :global .ant-table-tbody > tr > td:nth-child(2),
.clients :global .ant-table-thead > tr > th:nth-child(2) {
  min-width: 140px;
}
.clients :global .ant-table-tbody > tr > td:nth-child(4),
.clients :global .ant-table-thead > tr > th:nth-child(4),
.clients :global .ant-table-tbody > tr > td:nth-child(5),
.clients :global .ant-table-thead > tr > th:nth-child(5),
.clients :global .ant-table-tbody > tr > td:nth-child(6),
.clients :global .ant-table-thead > tr > th:nth-child(6),
.clients :global .ant-table-tbody > tr > td:nth-child(8),
.clients :global .ant-table-thead > tr > th:nth-child(8) {
  min-width: 100px;
}
.clients :global .ant-table-tbody > tr > td:nth-child(7),
.clients :global .ant-table-thead > tr > th:nth-child(7) {
  min-width: 80px;
}
.clients :global .ant-table-tbody > tr > td:nth-child(9),
.clients :global .ant-table-thead > tr > th:nth-child(9) {
  min-width: 120px;
}
