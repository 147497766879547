.calendar-typography {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
}
.side-menu-item {
  font-size: 16px;
  border-radius: var(--menu-border-radius);
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:after {
  content: none !important;
}
.side-menu-item:active {
  background: transparent;
}
.side-menu-item-icon {
  line-height: 1;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item .side-menu-item-text {
  text-transform: capitalize;
  color: var(--side-menu-menu-item-text-color);
}
.side-menu-item:hover .side-menu-item-icon,
.side-menu-item:hover .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global(.ant-menu-item-selected) {
  background: var(--side-menu-active-menu-item-background);
}
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-icon,
.side-menu-item:global(.ant-menu-item-selected) .side-menu-item-text {
  color: var(--side-menu-active-menu-item-text-color);
}
.side-menu-item:global.ant-menu-item {
  height: 45px;
  padding: 6px 16px;
}
.side-menu-item:global.ant-menu-item:not(:last-child) {
  margin: 0 0 16px;
}
.side-menu-item:global.ant-menu-item:last-child {
  margin: 0;
}
.disabled-button[disabled],
.disabled-button[disabled]:hover,
.disabled-button[disabled]:focus,
.disabled-button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.select-option {
  align-items: center;
  height: 41px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--text-color);
}
.select-option:hover {
  background-color: var(--dropdown-menu-hover);
}
.select-option:global.ant-select-item-option-selected,
.select-option:global.ant-select-item-option-active {
  background-color: var(--dropdown-menu-hover);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .ant-pagination {
  display: flex;
  border-radius: var(--pagination-border-radius);
  background: var(--table-pagination-bg);
}
.pagination .ant-pagination-next svg {
  transform: rotate(180deg);
}
.pagination .ant-pagination-prev,
.pagination .ant-pagination-next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 35px;
  transition: 0.2s linear;
}
.pagination .ant-pagination-prev svg:hover,
.pagination .ant-pagination-next svg:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-prev svg path,
.pagination .ant-pagination-next svg path {
  stroke: var(--table-pagination-arrow);
}
.pagination .ant-pagination-prev.ant-pagination-disabled svg,
.pagination .ant-pagination-next.ant-pagination-disabled svg {
  opacity: 1;
}
.pagination .ant-pagination-item {
  height: 41px;
  width: 35px;
  background: transparent;
  border: none;
}
.pagination .ant-pagination-item:hover {
  opacity: 0.4;
}
.pagination .ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--pagination-item-text-color);
}
.pagination .ant-pagination-item-active {
  background: var(--table-pagination-bg);
  position: relative;
  z-index: 1;
}
.pagination .ant-pagination-item-active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  z-index: -1;
  margin: auto;
  border-radius: var(--pagination-item-active-border-radius);
  background: var(--pagination-item-active-background);
}
.pagination .ant-pagination-item-active a {
  cursor: default;
  color: var(--pagination-item-active-text-color);
}
.pagination .ant-pagination-item-active:hover {
  opacity: 1;
}
.pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--table-pagination-arrow);
  transform: translate(0, 10px);
}
.pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.pagination .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0.4;
}
.pagination .ant-spin-container .ant-pagination.ant-table-pagination {
  display: none;
}
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus {
  font-size: 14px !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color) !important;
  background: transparent !important;
  caret-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.default-table-padding .ant-table table .ant-table-cell {
  padding: 9px 16px 9px 6px;
}
.default-table-padding .ant-table table .ant-table-cell:first-child {
  padding: 9px 16px 10px 9px;
}
.table-nowrap .ant-table-tbody > tr > td,
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.common-button :global a.ant-btn {
  padding-top: 9px !important;
  line-height: 150%;
}
.common-button:global[ant-click-animating-without-extra-node]:after,
.common-button:global.ant-click-animating-node {
  animation: none;
}
.common-button:global.ant-btn {
  box-shadow: none;
}
.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 41px;
  padding: 9px 20px 10px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--button-text-color);
  border: none;
  border-radius: var(--button-border-radius);
  box-shadow: none;
}
.button:hover,
.button:active,
.button:focus {
  color: var(--button-text-color);
}
.button-content .button-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.button[disabled],
.button[disabled]:hover,
.button[disabled]:focus,
.button[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.button-container {
  overflow: hidden;
}
.button-container > span:nth-child(2) {
  margin-left: 8px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.gradient {
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
}
.gradient:focus {
  background: var(--button-primary-normal-background-color);
}
.gradient:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.with-primary-icon {
  padding: 0 16px;
}
.with-primary-icon .button-content {
  height: 100%;
}
@media (max-width: 575.98px) {
  .with-primary-icon .button-content {
    width: fit-content;
  }
}
.with-primary-icon .icon-component {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  margin-right: 16px;
  border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
}
.with-primary-icon .icon-component svg path {
  fill: var(--ant-icon-fill-color);
}
.with-primary-icon .icon-component:focus {
  background: var(--button-primary-normal-background-color);
}
.with-primary-icon .icon-component:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.with-primary-icon:hover .icon-component {
  background: var(--button-primary-normal-background-color);
}
.with-primary-icon:hover .icon-component:before {
  opacity: 1;
}
.with-primary-icon:active .icon-component {
  background: #1811A2;
}
.with-primary-icon:active:before .icon-component {
  background: #1811A2;
}
.with-primary-icon .icon-component {
  position: relative;
  padding: 10px;
  margin-right: 8px;
  border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
}
.with-primary-icon .icon-component:focus {
  background: var(--button-primary-normal-background-color);
}
.with-primary-icon .icon-component:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.button-gradient {
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
  transition: background 0.2s ease-in-out;
}
.button-gradient:focus {
  background: var(--button-primary-normal-background-color);
}
.button-gradient:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.button-gradient:hover,
.button-gradient:focus:hover {
  background: var(--button-primary-normal-background-color);
}
.button-gradient:not([disabled]):hover:before {
  opacity: 1;
}
.button-gradient:active {
  background: var(--button-primary-pressed-background-color);
}
.button-gradient:active:before {
  background: var(--button-primary-pressed-background-color);
}
.primary {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 41px;
  padding: 9px 20px 10px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--button-text-color);
  border: none;
  border-radius: var(--button-border-radius);
  box-shadow: none;
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
  transition: background 0.2s ease-in-out;
}
.primary:hover,
.primary:active,
.primary:focus {
  color: var(--button-text-color);
}
.primary-content .button-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.primary[disabled],
.primary[disabled]:hover,
.primary[disabled]:focus,
.primary[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.primary:focus {
  background: var(--button-primary-normal-background-color);
}
.primary:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.primary:hover,
.primary:focus:hover {
  background: var(--button-primary-normal-background-color);
}
.primary:not([disabled]):hover:before {
  opacity: 1;
}
.primary:active {
  background: var(--button-primary-pressed-background-color);
}
.primary:active:before {
  background: var(--button-primary-pressed-background-color);
}
.primary .link {
  width: 100%;
  height: 100%;
  color: var(--button-text-color);
}
.text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 41px;
  padding: 9px 20px 10px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--button-text-color);
  border: none;
  border-radius: var(--button-border-radius);
  box-shadow: none;
  color: var(--text-color);
  background: none;
}
.text:hover,
.text:active,
.text:focus {
  color: var(--button-text-color);
}
.text-content .button-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text[disabled],
.text[disabled]:hover,
.text[disabled]:focus,
.text[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.text:hover,
.text:active,
.text:focus:hover {
  color: var(--text-color);
  background: var(--button-hovered);
}
.text:focus {
  background: none;
}
.default {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 41px;
  padding: 9px 20px 10px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--button-text-color);
  border: none;
  border-radius: var(--button-border-radius);
  box-shadow: none;
  color: var(--text-color);
  background: var(--selector-background);
}
.default:hover,
.default:active,
.default:focus {
  color: var(--button-text-color);
}
.default-content .button-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.default[disabled],
.default[disabled]:hover,
.default[disabled]:focus,
.default[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.default:hover,
.default:focus {
  color: var(--text-color);
  background: var(--button-hovered);
}
.default:active {
  color: var(--text-color);
  background: var(--button-dissable);
}
.outline {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 41px;
  padding: 9px 20px 10px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--button-text-color);
  border: none;
  border-radius: var(--button-border-radius);
  box-shadow: none;
  color: var(--button-outline-text-color);
  background-color: transparent;
  border: 1px solid var(--button-outline-border-color);
}
.outline:hover,
.outline:active,
.outline:focus {
  color: var(--button-text-color);
}
.outline-content .button-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.outline[disabled],
.outline[disabled]:hover,
.outline[disabled]:focus,
.outline[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.outline:hover,
.outline:focus {
  color: var(--button-outline-text-color);
  background-color: var(--transparent-gradient);
  border: 1px solid var(--button-outline-border-color);
}
.outline:active {
  color: var(--text-color);
  background: var(--button-dissable);
}
.button-with-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 41px;
  padding: 9px 20px 10px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--button-text-color);
  border: none;
  border-radius: var(--button-border-radius);
  box-shadow: none;
  color: var(--text-color);
  background: var(--selector-background);
  padding: 0 16px;
  transition: background-color 0s;
}
.button-with-icon:hover,
.button-with-icon:active,
.button-with-icon:focus {
  color: var(--button-text-color);
}
.button-with-icon-content .button-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.button-with-icon[disabled],
.button-with-icon[disabled]:hover,
.button-with-icon[disabled]:focus,
.button-with-icon[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.button-with-icon:hover,
.button-with-icon:focus {
  color: var(--text-color);
  background: var(--button-hovered);
}
.button-with-icon:active {
  color: var(--text-color);
  background: var(--button-dissable);
}
.button-with-icon .button-container {
  justify-content: flex-start;
  height: 41px;
  width: 100%;
}
.copy {
  padding: 0 16px;
  padding: 0 16px 0 0;
}
.copy .button-content {
  height: 100%;
}
@media (max-width: 575.98px) {
  .copy .button-content {
    width: fit-content;
  }
}
.copy .icon-component {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  margin-right: 16px;
  border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
}
.copy .icon-component svg path {
  fill: var(--ant-icon-fill-color);
}
.copy .icon-component:focus {
  background: var(--button-primary-normal-background-color);
}
.copy .icon-component:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.copy:hover .icon-component {
  background: var(--button-primary-normal-background-color);
}
.copy:hover .icon-component:before {
  opacity: 1;
}
.copy:active .icon-component {
  background: #1811A2;
}
.copy:active:before .icon-component {
  background: #1811A2;
}
.copy .icon-component {
  position: relative;
  padding: 10px;
  margin-right: 8px;
  border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
}
.copy .icon-component:focus {
  background: var(--button-primary-normal-background-color);
}
.copy .icon-component:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.icon {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
}
.icon,
.icon:hover,
.icon:focus-within {
  color: var(--button-text-color);
  background-color: transparent;
}
.icon[disabled],
.icon[disabled]:hover,
.icon[disabled]:focus,
.icon[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.icon-primary {
  transition: 0.2s ease-in-out;
  background: var(--button-primary-normal-background-color);
  z-index: 1;
  transition: background 0.2s ease-in-out;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
}
.icon-primary:focus {
  background: var(--button-primary-normal-background-color);
}
.icon-primary:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--button-primary-hover-background-color);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.45s;
}
.icon-primary:hover,
.icon-primary:focus:hover {
  background: var(--button-primary-normal-background-color);
}
.icon-primary:not([disabled]):hover:before {
  opacity: 1;
}
.icon-primary:active {
  background: var(--button-primary-pressed-background-color);
}
.icon-primary:active:before {
  background: var(--button-primary-pressed-background-color);
}
.icon-primary,
.icon-primary:hover,
.icon-primary:focus-within {
  color: var(--button-text-color);
  background-color: transparent;
}
.icon-primary[disabled],
.icon-primary[disabled]:hover,
.icon-primary[disabled]:focus,
.icon-primary[disabled]:active {
  color: var(--text-color);
  background: var(--default-setting-button);
}
.icon-default {
  width: 24px;
  height: 24px;
  padding: 0;
  color: var(--menu-item-color);
  background: none;
  border: none;
}
.icon-default:hover,
.icon-default:focus {
  color: var(--text-color);
  background: none;
}
.icon-default:active {
  color: var(--menu-item-color);
  background: none;
}
